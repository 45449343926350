import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import AlertModal from '../../../components/AlertModal';
import { closeConfirmPlayAlbumModale } from '../../../store/modaleSlice';
import { RootState } from '../../../store/rootReducer';
import store from '../../../store/store';
import { setToast } from '../../../store/toastSlice';
import { getAllSearchedTracksSelector } from '../../Search/selector';
import SocketService from '../../../services/socket';

const ConfirmPlayAlbum = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const confirmPlayAlbumModale = useSelector((state: RootState) => state.ModaleReducer.confirmPlayAlbumModale);
  const confirmPlayAlbumModaleIsOpen = useSelector(
    (state: RootState) => state.ModaleReducer.confirmPlayAlbumModaleIsOpen,
  );
  return (
    <AlertModal
      isOpen={confirmPlayAlbumModaleIsOpen}
      onDidDismiss={() => {
        dispatch(closeConfirmPlayAlbumModale());
      }}
      header={t('playAlbumAlertTitle')}
      subHeader={t('playAlbumAlertDesc')}
      buttons={[
        {
          text: t('cancel'),
          role: 'cancel',
        },
        {
          text: t('play'),
          role: 'confirm',
          handler: () => {
            const state = store.getState();
            const allCombinedTracks = getAllSearchedTracksSelector(state);
            confirmPlayAlbumModale?.trackIds
              .slice()
              .reverse()
              .forEach((trackId) => {
                const foundTrack = allCombinedTracks.find((qobuzTrack) => qobuzTrack.id === trackId);
                if (foundTrack) {
                  SocketService.prependToPriorityQueue(foundTrack);
                }
              });
            dispatch(
              setToast({
                isOpen: true,
                header: t('albumAdded'),
                message: t('albumAddedMessage'),
                duration: 5000,
              }),
            );
          },
        },
      ]}
    />
  );
};

export default ConfirmPlayAlbum;
