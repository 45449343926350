import { Redirect, Route, Switch } from 'react-router-dom';
import { getPlatforms, IonRouterOutlet } from '@ionic/react';
import Tabs from './components/Tabs';
import { useSelector } from 'react-redux';
import { RootState } from './store/rootReducer';
import { useEffect, useState } from 'react';
import { AppStateLabel, useAppPages } from './features/hooks/useAppPages';
import { IonReactRouter } from '@ionic/react-router';
import Player from './features/Player';
import ToastContainer from './features/ToastContainer';
import { isAdminApp, isWhiteLabel } from './utils/platform';
import { Helmet } from 'react-helmet';
import DownloadAppAlertContainer from './features/DownloadAppAlert';

const App = () => {
  const appState = useSelector((state: RootState) => state.AppReducer);
  const { params, currentMusic } = useSelector((state: RootState) => state.PlayerReducer);
  const [currentAppState, setCurrentAppState] = useState<AppStateLabel>('boxUnavailable');
  const appPages = useAppPages();
  const platforms = getPlatforms();

  useEffect(() => {
    setCurrentAppState(
      (() => {
        if (isAdminApp() || appState.isAdminMode) {
          if (params.isTshokoConnected) {
            if (params.tshokoStatus === 'start') {
              return 'playingAdmin';
            } else {
              return 'noProg';
            }
          } else {
            return 'boxUnavailable';
          }
        } else {
          if (
            params.isTshokoConnected &&
            params.tshokoStatus === 'start' &&
            params.isJukeboxModeOn &&
            appState.boxDetail?.options.jukebox
          ) {
            return appState.boxDetail?.options.jukeboxrestricted ? 'playingUserLimitedJukebox' : 'playingUser';
          }
          return 'jukeboxUnavailable';
        }
      })(),
    );
  }, [
    appState.isAdminMode,
    params.isTshokoConnected,
    params.tshokoStatus,
    params.isJukeboxModeOn,
    appState.boxDetail?.options.jukebox,
    appState.boxDetail?.options.jukeboxrestricted,
    appState.boxDetail?._id,
  ]);

  return (
    <>
      <Helmet>
        <title>{appState.isAdminMode && !isWhiteLabel() ? 'MyTshoko' : 'Jukebox'}</title>
      </Helmet>
      <IonReactRouter>
        {platforms.includes('mobileweb') && appState.isAdminMode && !isWhiteLabel() && (
          <DownloadAppAlertContainer platforms={platforms} />
        )}
        <ToastContainer />
        <Player currentAppState={currentAppState} />
        <Tabs key={`${currentAppState}-${!!currentMusic}`} tabs={appPages[currentAppState]}>
          <IonRouterOutlet>
            <Switch>
              {appPages[currentAppState].map((page) => (
                <Route key={page.href} exact path={page.href} component={() => page.component} />
              ))}
              <Redirect to={appPages[currentAppState][0].href} />
            </Switch>
          </IonRouterOutlet>
        </Tabs>
      </IonReactRouter>
    </>
  );
};

export default App;
