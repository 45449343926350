import { useRef, useState } from 'react';
import { IonCol, IonGrid, IonRow } from '@ionic/react';
import Button from '../../components/Button';
import { useTranslation } from 'react-i18next';
import TextField from '../../components/TextField';
import Typography from '../../components/Typography';
import DottedWrapper from '../../components/DottedWrapper/DottedWrapper';
import { useGetSettingsQuery } from '../../services/cdn.service';
import styled from 'styled-components';
import { useContext } from 'react';
import { KeyboardContext } from '../../contexts/KeyboardContext';
import { isPlatform } from '@ionic/react';
import { capitalize, isValidEmail } from '../../utils/string';
import { Link } from 'react-router-dom';

export const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: calc(var(--ion-safe-area-top) - 8px);

  & .content {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    background-position: center;
    background-size: cover;
  }
`;

export const StyledForm = styled(({ ...props }) => {
  const { keyboardState } = useContext(KeyboardContext);
  return (
    <div
      {...props}
      style={{
        transform: `translate(0px, calc(${
          keyboardState?.state === 'open' || keyboardState?.state === 'opening'
            ? `(-${(isPlatform('ios') && keyboardState?.keyboardHeight) || 0}px / 2) + var(--ion-safe-area-bottom, 0px)`
            : `0px`
        }))`,
      }}
    />
  );
})`
  transition: transform 0.25s;
  display: flex;
  flex: 1;
`;

export const StyledForgotPasswordButton = styled(Button)`
  margin-top: 15px;
`;
export type ForgotPasswordProps = {
  onClick: (email: string) => void;
  errorMessage?: string;
  spinning?: boolean;
  loading?: boolean;
  success?: boolean;
};

export const ForgotPassword = ({ success, onClick, errorMessage, loading }: ForgotPasswordProps): JSX.Element => {
  const { t } = useTranslation();
  const emailRef = useRef<HTMLIonInputElement>(null);
  const [email, setEmail] = useState('');
  const { data: settings } = useGetSettingsQuery();

  const logoNode = (
    <div>
      <img
        src="./theme/mobileapp-logo.png"
        style={{
          width: '100%',
        }}
      />
    </div>
  );

  return (
    <StyledContentContainer>
      <div
        className="content"
        style={{
          backgroundImage: `url(${'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='})`,
        }}
      >
        <StyledForm>
          <IonGrid
            style={{
              margin: 'auto',
            }}
            className="ion-align-items-center"
          >
            <IonRow className="ion-justify-content-center ion-text-center">
              <IonCol
                style={{
                  maxWidth: 320,
                }}
              >
                {settings?.button?.href?.includes('tshoko.fr') ? (
                  <DottedWrapper size={22}>{logoNode}</DottedWrapper>
                ) : (
                  logoNode
                )}
                {!success && (
                  <>
                    <IonRow class="ion-margin ion-justify-content-center">
                      <Typography>{t('forgotPasswordText')}</Typography>
                    </IonRow>
                    <TextField
                      aria-label={t('email')}
                      type="email"
                      name="email"
                      id="email"
                      ref={emailRef}
                      enterkeyhint="enter"
                      clearInput
                      disabled={loading}
                      required
                      loading={loading}
                      onIonInput={(e) => setEmail(e.target.value ? `${e.target.value}` : '')}
                      value={email}
                      placeholder={capitalize(t('email'))}
                    />
                    <IonRow class="ion-margin ion-justify-content-center">
                      <Button
                        spinning={loading}
                        onClick={() => onClick(email)}
                        disabled={!isValidEmail(email)}
                        loading={loading}
                      >
                        {t('sendRequest')}
                      </Button>
                      <Link to={'/login'}>
                        <StyledForgotPasswordButton fill="clear" size="small">
                          {t('backToLogin')}
                        </StyledForgotPasswordButton>
                      </Link>
                    </IonRow>
                  </>
                )}
                {success && (
                  <IonRow class="ion-margin ion-justify-content-center">
                    <Typography>{t('requestSent')}</Typography>
                  </IonRow>
                )}
              </IonCol>
            </IonRow>
            {errorMessage && (
              <IonRow className="ion-justify-content-center">
                <IonCol size="auto">
                  <Typography color={'danger'}>{t(errorMessage)}</Typography>
                </IonCol>
              </IonRow>
            )}
          </IonGrid>
        </StyledForm>
      </div>
    </StyledContentContainer>
  );
};

export default ForgotPassword;
