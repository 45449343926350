import React from 'react';
import { IonIcon, IonRippleEffect, IonSkeletonText } from '@ionic/react';
import { StyledIonButton } from './PlayButton.styled';
import { play } from 'ionicons/icons';

export type PlayButtonProps = {
  onClick?: () => void;
  loading?: boolean;
  disabled?: boolean;
};

const PlayButton = (props: PlayButtonProps) => {
  return props.loading ? (
    <IonSkeletonText animated={true} style={{ width: 48, height: 48 }} />
  ) : (
    <StyledIonButton
      fill="solid"
      className="play-button"
      shape={'round'}
      onClick={props.onClick}
      disabled={props.disabled || false}
    >
      <IonRippleEffect />
      <div>
        <IonIcon slot="icon-only" icon={play} />
      </div>
    </StyledIonButton>
  );
};

export default PlayButton;
