import { IonItem, IonInput } from '@ionic/react';
import styled from 'styled-components';
import { InputProps } from './TextField';

export const StyledIonItem = styled(IonItem)`
  --padding-start: 0;
  --inner-padding-end: 0;
  --border-style: 0;
  --background: none;
`;

export const StyledIonInput = styled(IonInput)`
  &.styledIonInput {
    background-color: rgba(var(--ion-text-color-rgb), 0.12);
    border: solid;
    border-width: 0 0 0 4px;
    border-color: rgba(var(--ion-text-color-rgb), 0.54);

    &:before {
      content: '';
      position: absolute;
      width: 0px;
      height: 100%;
      left: -4px;
      background: var(--ion-color-primary);
      transition: all 0.2s;
      transform: scaleY(0);
    }

    &.has-focus {
      &:before {
        width: 4px;
        transform: scaleY(1);
      }
    }

    & input {
      margin-left: ${(props: InputProps) => (props.startIcon ? '32px' : '8px')};
    }

    & .start-icon {
      display: flex;
      align-items: center;
      color: rgba(var(--ion-text-color-rgb), 0.54);
      position: absolute;
      left: 8px;
      font-size: 20px;
      height: 24px;
    }
  }
`;

export const StyledInputContainer = styled.div`
  & .styledIonInput {
    min-height: 36px;
    margin-top: 8px;
  }
`;
