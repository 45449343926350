import styled from 'styled-components';
import { IonSearchbar } from '@ionic/react';

export const StyledIonSearchbar = styled(IonSearchbar)`
  &.sc-ion-searchbar-ios-h {
    --background: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.12);
    --color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.87);
    --placeholder-opacity: 0;

    transition: all 0.3s;
    width: 48px;
    padding: 0;
    padding-inline: 0px;
    height: 48px;
    cursor: text;

    &.searchbar-has-focus,
    &.searchbar-has-value,
    &.open {
      width: 100%;
      --placeholder-opacity: 0.5;

      & .searchbar-input-container {
        & input {
          padding-left: 48px !important;
          padding-inline: 48px;
          border-radius: 24px;
        }

        & input::placeholder {
          visibility: visible;
        }

        & .searchbar-search-icon {
          padding-left: 15px;
        }

        & .searchbar-clear-button {
          padding-right: 15px;
        }
      }
    }

    & .searchbar-input-container {
      height: 48px;
      min-width: 48px;

      & input::placeholder {
        visibility: hidden;
      }

      & .searchbar-search-icon {
        margin: 0px !important;
        padding-inline: 9px;
      }
    }
  }
`;
