const levenshteinDistance = (str1 = '', str2 = '') => {
  const track = Array(str2.length + 1)
    .fill(null)
    .map(() => Array(str1.length + 1).fill(null));
  for (let i = 0; i <= str1.length; i += 1) {
    track[0][i] = i;
  }
  for (let j = 0; j <= str2.length; j += 1) {
    track[j][0] = j;
  }
  for (let j = 1; j <= str2.length; j += 1) {
    for (let i = 1; i <= str1.length; i += 1) {
      const indicator = str1[i - 1] === str2[j - 1] ? 0 : 1;
      track[j][i] = Math.min(
        track[j][i - 1] + 1, // deletion
        track[j - 1][i] + 1, // insertion
        track[j - 1][i - 1] + indicator, // substitution
      );
    }
  }
  return track[str2.length][str1.length];
};

export const isValidEmail = (email: unknown) => {
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return regex.test(String(email).toLowerCase());
};

export const isValidPassword = (password: unknown) => {
  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9\s])(?=.{8,})/;

  return regex.test(String(password));
};

export const stringsAreSimilar = (s1: string, s2: string) =>
  s1.toLowerCase().trim() === s2.toLowerCase().trim() ||
  s1.toLowerCase().trim().includes(s2.toLowerCase().trim()) ||
  s2.toLowerCase().trim().includes(s1.toLowerCase().trim()) ||
  levenshteinDistance(s1.toLowerCase().trim(), s2.toLowerCase().trim()) < 2;

export const capitalize = (text: string) => String(text).charAt(0).toUpperCase() + String(text).slice(1);
