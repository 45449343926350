import { Route, Switch, RouteComponentProps } from 'react-router-dom';
import ForgotPasswordLayout from '../layouts/ForgotPasswordLayout';
import { IonPage } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { usePostForgotPasswordMutation } from '../services/api.service';

const ForgotPassword = ({ match }: RouteComponentProps<Record<string, string>>) => {
  const { t } = useTranslation();
  const [query, { isLoading, isSuccess, error }] = usePostForgotPasswordMutation();

  return (
    <IonPage>
      <Switch>
        <Route
          exact
          path={`${match.path}`}
          component={() => (
            <ForgotPasswordLayout
              onClick={(email) => {
                query({ email });
              }}
              success={isSuccess}
              errorMessage={error ? t('errorOccured') : undefined}
              spinning={isLoading}
            />
          )}
        />
      </Switch>
    </IonPage>
  );
};

export default ForgotPassword;
