import React, { useEffect, useRef, useState } from 'react';
import { IonHeader, IonChip, IonIcon } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import {
  StyledPageContent,
  StyledHeaderContainer,
  SearchBarContainer,
  StyledContentContainer,
} from './SearchLayout.styled';
import { heartOutline } from 'ionicons/icons';
import useResizeObserver from '@react-hook/resize-observer';
import { AlbumCardDefaultProps } from '../../../components/AlbumCard';
import { AmbianceCardDefaultProps } from '../../../components/AmbianceCard';
import { ArtistCardDefaultProps } from '../../../components/ArtistCard';
import { IndependentPlaylistsCardDefaultProps } from '../../../components/IndependentPlaylistsCard';
import { PlaylistCardDefaultProps } from '../../../components/PlaylistCard';
import SearchBar from '../../../components/SearchBar';
import { SoundDesignCardDefaultProps } from '../../../components/SoundDesignCard';
import { TrackItemDefaultProps } from '../../../components/TrackItem';
import Typography from '../../../components/Typography';
import SearchTabAlbums from './tabs/SearchTabAlbums';
import SearchTabAmbiances from './tabs/SearchTabAmbiances';
import SearchTabArtists from './tabs/SearchTabArtists';
import SearchTabMostPopular from './tabs/SearchTabMostPopular';
import SearchTabPlaylists from './tabs/SearchTabPlaylists';
import SearchTabSoundLibrary from './tabs/SearchTabSoundLibrary';
import SearchTabTracks from './tabs/SearchTabTracks';
import SearchTabSpots from './tabs/SearchTabSpots';
import { SpotCardDefaultProps } from '../../../components/SpotCard';
import { useFeatureAvailable } from '../../../utils/hooks/useFeatureAvailable';

export type SearchLayoutProps = {
  adminMode: boolean;
  onSearch: (searchValue: string) => void;
  searchValue: string;
  searchResults?: {
    playlists: PlaylistCardDefaultProps['playlist'][];
    sharedPlaylists: PlaylistCardDefaultProps['playlist'][];
    albums: AlbumCardDefaultProps['album'][];
    ambiances: AmbianceCardDefaultProps['ambiance'][];
    tracks: TrackItemDefaultProps['track'][];
    artists: ArtistCardDefaultProps['artist'][];
    spots: SpotCardDefaultProps['spot'][];
    mostPopular?:
      | {
          type: 'albums';
          item: AlbumCardDefaultProps['album'];
        }
      | {
          type: 'tracks';
          item: TrackItemDefaultProps['track'];
        }
      | {
          type: 'artists';
          item: ArtistCardDefaultProps['artist'];
        };
  };

  soundLibrary?: {
    playlists: PlaylistCardDefaultProps['playlist'][];
    sharedPlaylists: PlaylistCardDefaultProps['playlist'][];
    soundDesigns: (SoundDesignCardDefaultProps['soundDesign'] & {
      independentPlaylists: Pick<IndependentPlaylistsCardDefaultProps, 'playlists' | 'total'>;
    })[];
    spots: SpotCardDefaultProps['spot'][];
  };
  isLoading?: boolean;
  isPlaylistsFetching?: boolean;
  isSharedPlaylistsFetching?: boolean;
  isTracksFetching?: boolean;
  isArtistsFetching?: boolean;
  isAlbumsFetching?: boolean;
  isAmbiancesFetching?: boolean;
  isSpotsFetching?: boolean;
  loadDataTracks?: () => void;
  loadDataArtists?: () => void;
  loadDataAlbums?: () => void;
  loadDataPlaylists?: () => void;
  loadDataSharedPlaylists?: () => void;
  loadDataSpots?: () => void;
  onClickTrack: (track: TrackItemDefaultProps['track']) => void;
  onClickTrackVote?: (track: TrackItemDefaultProps['track']) => void;
  onBackToProgClick?: () => void;
  onClickSpot: (spot: SpotCardDefaultProps['spot']) => void;
  onClickSpotCard: (spot: SpotCardDefaultProps['spot']) => void;
};

const SearchLayout = (props: SearchLayoutProps) => {
  const { t } = useTranslation();
  const searchBarRef = useRef<HTMLIonSearchbarElement>(null);
  const [activeTab, setActiveTab] = useState<string>();

  const showTabsSoundLibrary = props.adminMode && !props.searchValue;
  const showTabsAlbums = !props.isLoading && !!props.searchResults?.albums.length;
  const showTabsAmbiance =
    props.adminMode && !props.isLoading && !props.isAmbiancesFetching && !!props.searchResults?.ambiances.length;
  const showTabsArtists = !props.isLoading && !!props.searchResults?.artists.length;
  const showTabsPlaylists =
    props.adminMode && !props.isLoading && !props.isAmbiancesFetching && !!props.searchResults?.playlists.length;
  const showTabsSharedPlaylists =
    props.adminMode && !props.isLoading && !props.isAmbiancesFetching && !!props.searchResults?.sharedPlaylists.length;
  const showTabsTracks = !props.isLoading && !!props.searchResults?.tracks.length;
  const showTabsSpots =
    useFeatureAvailable('playSpotFeature') &&
    !!props.searchResults?.spots.length &&
    props.adminMode &&
    !props.isLoading;
  const tabs = React.useMemo(
    () => [
      showTabsSoundLibrary ? 'soundLibrary' : 'mostPopular',
      ...(showTabsAlbums ? ['albums'] : []),
      ...(showTabsAmbiance ? ['ambiances'] : []),
      ...(showTabsArtists ? ['artists'] : []),
      ...(showTabsPlaylists ? ['playlists'] : []),
      ...(showTabsSharedPlaylists ? ['sharedPlaylists'] : []),
      ...(showTabsTracks ? ['tracks'] : []),
      ...(showTabsSpots ? ['spots'] : []),
    ],
    [
      showTabsAlbums,
      showTabsAmbiance,
      showTabsArtists,
      showTabsPlaylists,
      showTabsSharedPlaylists,
      showTabsTracks,
      showTabsSoundLibrary,
      showTabsSpots,
    ],
  );

  useEffect(() => {
    if (!activeTab || !tabs.includes(activeTab)) {
      setActiveTab(tabs[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.searchResults]);

  useResizeObserver(searchBarRef, async (entry) => {
    const input = await (entry.target as HTMLIonSearchbarElement).getInputElement();
    if (!props.adminMode) {
      input.focus();
    }
  });

  const renderTab = React.useMemo(() => {
    return (
      <>
        {activeTab === 'soundLibrary' && (
          <SearchTabSoundLibrary
            loading={props.isLoading || props.isAmbiancesFetching}
            soundLibrary={props.soundLibrary}
            onClickPlaylists={() => setActiveTab('playlists')}
            onClickSharedPlaylists={() => setActiveTab('sharedPlaylists')}
            isPlaylistsFetching={props.isPlaylistsFetching}
            onClickSpotsHeader={() => setActiveTab('spots')}
            onClickSpotCard={props.onClickSpotCard}
            onBackToProgClick={props.onBackToProgClick}
            loadDataPlaylists={props.loadDataPlaylists}
            loadDataSharedPlaylists={props.loadDataSharedPlaylists}
            isSharedPlaylistsFetching={props.isSharedPlaylistsFetching}
          />
        )}
        {activeTab === 'mostPopular' && (
          <SearchTabMostPopular
            adminMode={props.adminMode}
            loading={props.isLoading}
            searchResults={props.searchResults}
            onClickTrack={props.onClickTrack}
            onClickTrackVote={props.onClickTrackVote}
            onClickTracks={() => setActiveTab('tracks')}
            onClickArtists={() => setActiveTab('artists')}
            isArtistsFetching={props.isArtistsFetching}
            loadDataArtists={props.loadDataArtists}
            onClickAlbums={() => setActiveTab('albums')}
            isAlbumsFetching={props.isAlbumsFetching}
            loadDataAlbums={props.loadDataAlbums}
            onClickPlaylists={() => setActiveTab('playlists')}
            isPlaylistsFetching={props.isPlaylistsFetching}
            loadDataPlaylists={props.loadDataPlaylists}
            onClickAmbiances={() => setActiveTab('ambiances')}
            isAmbiancesFetching={props.isAmbiancesFetching}
            onClickSpots={() => setActiveTab('spots')}
            onClickSpotCard={props.onClickSpotCard}
            isSpotsFetching={props.isSpotsFetching}
            loadDataSpots={props.loadDataSpots}
            loadDataSharedPlaylists={props.loadDataSharedPlaylists}
            isSharedPlaylistsFetching={props.isSharedPlaylistsFetching}
          />
        )}
        {activeTab === 'tracks' && (
          <SearchTabTracks
            loading={props.isLoading || props.isTracksFetching}
            onLoadData={props.loadDataTracks}
            tracks={(!props.isLoading && props.searchResults?.tracks) || []}
            onClickTrack={props.onClickTrack}
            onClickTrackVote={props.onClickTrackVote}
            adminMode={props.adminMode}
          />
        )}
        {activeTab === 'playlists' && (
          <SearchTabPlaylists
            loading={props.isLoading || props.isPlaylistsFetching}
            onLoadData={props.loadDataPlaylists}
            playlists={(!props.isLoading && props.searchResults?.playlists) || []}
          />
        )}
        {activeTab === 'sharedPlaylists' && (
          <SearchTabPlaylists
            loading={props.isLoading || props.isSharedPlaylistsFetching}
            onLoadData={props.loadDataSharedPlaylists}
            playlists={(!props.isLoading && props.searchResults?.sharedPlaylists) || []}
          />
        )}
        {activeTab === 'artists' && (
          <SearchTabArtists
            loading={props.isLoading || props.isArtistsFetching}
            onLoadData={props.loadDataArtists}
            artists={(!props.isLoading && props.searchResults?.artists) || []}
          />
        )}
        {activeTab === 'albums' && (
          <SearchTabAlbums
            loading={props.isLoading || props.isAlbumsFetching}
            onLoadData={props.loadDataAlbums}
            albums={(!props.isLoading && props.searchResults?.albums) || []}
          />
        )}
        {activeTab === 'ambiances' && (
          <SearchTabAmbiances
            loading={props.isLoading || props.isAmbiancesFetching}
            ambiances={(!props.isLoading && props.searchResults?.ambiances) || []}
          />
        )}
        {activeTab === 'spots' && (
          <SearchTabSpots
            loading={props.isLoading || props.isSpotsFetching}
            onLoadData={props.loadDataSpots}
            onClickSpot={props.onClickSpot}
            spots={(!props.isLoading && props.searchResults?.spots) || []}
          />
        )}
      </>
    );
  }, [
    activeTab,
    props.adminMode,
    props.isAlbumsFetching,
    props.isAmbiancesFetching,
    props.isArtistsFetching,
    props.isLoading,
    props.isPlaylistsFetching,
    props.isTracksFetching,
    props.isSpotsFetching,
    props.loadDataAlbums,
    props.loadDataArtists,
    props.loadDataPlaylists,
    props.loadDataTracks,
    props.loadDataSpots,
    props.onClickTrack,
    props.onClickTrackVote,
    props.onClickSpot,
    props.searchResults,
    props.soundLibrary,
    props.onBackToProgClick,
    props.onClickSpotCard,
    props.loadDataSharedPlaylists,
    props.isSharedPlaylistsFetching,
  ]);

  return (
    <StyledPageContent>
      <StyledHeaderContainer>
        <IonHeader>
          <SearchBarContainer>
            <SearchBar
              forceOpen
              {...(!searchBarRef.current?.value && props.searchValue
                ? { value: props.searchValue }
                : { value: searchBarRef.current?.value })}
              placeholder={props.adminMode ? t('searchAdminPlaceholder') : t('searchJukeboxPlaceholder')}
              ref={searchBarRef}
              onIonInput={(e) => {
                props.onSearch(e.target.value || '');
              }}
              debounce={250}
            />
          </SearchBarContainer>
          {props.searchResults && (
            <div style={{ overflow: 'auto', whiteSpace: 'nowrap', padding: '8px 0' }}>
              {tabs.map((tab) => (
                <IonChip
                  key={`tab-${tab}`}
                  color={activeTab === tab ? 'primary' : undefined}
                  onClick={() => setActiveTab(tab)}
                >
                  {t(tab)}
                </IonChip>
              ))}
            </div>
          )}
        </IonHeader>
      </StyledHeaderContainer>
      {props.searchResults ? (
        renderTab
      ) : (
        <StyledPageContent>
          <StyledContentContainer className="ion-padding">
            <div className="page-content">
              <Typography tag="h2" className="title">
                {t('searchJukeboxTitle')}
              </Typography>
              <Typography tag="h3" className="subtitle">
                {t('searchJukeboxSubTitle')}
                <IonIcon icon={heartOutline} color="danger" style={{ marginLeft: 8 }} />
              </Typography>
              <Typography tag="p" variant="secondary" className="desc">
                {t('searchJukeboxDesc')}
              </Typography>
            </div>
          </StyledContentContainer>
        </StyledPageContent>
      )}
    </StyledPageContent>
  );
};

export default SearchLayout;
