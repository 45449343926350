import { IonList, IonListHeader } from '@ionic/react';
import styled from 'styled-components';

export const StyledIonList = styled(IonList)`
  &.list {
    background: none;

    & .item:last-child {
      --border-color: transparent;
    }
  }
`;

export const StyledIonListHeader = styled(IonListHeader)`
  padding: 0;
  & .header-item {
    --background: none;
    --border-color: transparent;
    --padding-start: 0;

    &::part(native) {
      align-items: start;
    }

    &::part(detail-icon) {
      margin-top: 11px;
    }
    &.no-icon {
      &::part(detail-icon) {
        display: none;
      }
    }

    & .header-label {
      display: flex;
      flex-direction: column;
      width: auto;
      margin-left: 16px;
      margin-top: 8px;

      &.sc-ion-label-ios-s h3,
      &.sc-ion-label-ios-s h4,
      &.sc-ion-label-ios-s h5,
      &.sc-ion-label-ios-s h6 {
        font-size: inherit;
        font-weight: inherit;
        line-height: inherit;
      }

      & .sub-header-label {
        font-size: 14px;
        font-weight: 600;
        color: var(--text-color-secondary);
      }
    }
  }
`;
