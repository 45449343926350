import React, { useRef, useState } from 'react';
import { useLazyGetAllUserPlaylistsQuery, usePatchTrackToPlaylistMutation } from '../../services/api.service';
import { useTranslation } from 'react-i18next';
import { IonAlert, IonIcon, IonLabel } from '@ionic/react';
import { StyledIonItem } from '../../layouts/TrackBottomSheetLayout/TrackBottomSheetLayout.styled';
import { add } from 'ionicons/icons';
import { Track } from '../../store/playerSlice';
import { useGetTrackQuery } from '../../services/qobuz.service';
import { setToast } from '../../store/toastSlice';
import { useDispatch } from 'react-redux';
import { closeTrackBottomSheet } from '../../store/modaleSlice';
import { trackDtoFromQobuzDto } from '../../utils/adapters';

export type AddToPlaylistProps = {
  track: Track;
};

const AddToPlaylist = (props: AddToPlaylistProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const playlistSelectRef = useRef<HTMLIonAlertElement>(null);
  const [triggerAllUserPlaylist, { data: playlistsData, isError: playlistError }] = useLazyGetAllUserPlaylistsQuery();
  const { data: trackData, isError: trackNotFound } = useGetTrackQuery({ track_id: props.track.id.toString() });
  const [triggerPatchPlaylist, { isError: patchTrackToPlaylistError }] = usePatchTrackToPlaylistMutation();
  const [selectedPlaylistId, setSelectedPlaylistId] = useState<string>();

  if (trackNotFound || playlistError || patchTrackToPlaylistError) {
    dispatch(closeTrackBottomSheet());
    dispatch(
      setToast({
        isOpen: true,
        message: t('errorOccuredTryAgain'),
        type: 'error',
        duration: 5000,
      }),
    );
    return <></>;
  }

  return (
    <>
      <StyledIonItem
        button
        mode="md"
        onClick={async () => {
          await triggerAllUserPlaylist();
          playlistSelectRef.current?.present();
        }}
      >
        <div className="menu-item">
          <div className="item-icon">
            <IonIcon icon={add} />
          </div>
          <IonLabel>{t('addToPlaylist')}</IonLabel>
        </div>
      </StyledIonItem>

      <IonAlert
        ref={playlistSelectRef}
        mode="ios"
        header={t('addToPlaylist')}
        onDidDismiss={() => setSelectedPlaylistId(undefined)}
        buttons={[
          {
            text: t('cancel'),
            role: 'cancel',
          },
          {
            text: t('add'),
            role: 'confirm',
            ...(selectedPlaylistId
              ? {
                  handler: async () => {
                    if (!trackData) return;

                    triggerPatchPlaylist({
                      playlistIds: [selectedPlaylistId],
                      tracks: [trackDtoFromQobuzDto(trackData)],
                    });
                    dispatch(closeTrackBottomSheet());
                    dispatch(
                      setToast({
                        isOpen: true,
                        header: t('musicAdded'),
                        message: t('musicAddedContent'),
                        duration: 5000,
                      }),
                    );
                  },
                }
              : {
                  htmlAttributes: {
                    disabled: 'disabled',
                    style: {
                      opacity: 0.5,
                    },
                  },
                }),
          },
        ]}
        inputs={(playlistsData && playlistsData.length > 0
          ? playlistsData
          : [{ name: t('defaultPlaylist'), id: 'default' }]
        ).map((playlist) => ({
          type: 'checkbox',
          label: playlist.name,
          value: playlist.id,
          checked: playlist.id === selectedPlaylistId,
          handler: (e) => setSelectedPlaylistId(e.checked ? playlist.id : undefined),
        }))}
      />
    </>
  );
};

export default AddToPlaylist;
