import { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { setupIonicReact } from '@ionic/react';
import Login from './features/Login';
import ForgotPassword from './features/ForgotPassword';
import ResetPassword from './features/ResetPassword';
import Auth from './features/Auth';
import AppLoader from './AppLoader';
import ModalesContainer from './features/Modales/ModalesContainer';
import { SplashScreen } from '@capacitor/splash-screen';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/default.scss';
import './theme/variables.scss';

setupIonicReact({
  mode: 'ios',
});

const Router: React.FC = () => {
  document.body.classList.toggle(localStorage.getItem('theme') || 'dark', true);
  useEffect(() => {
    (async () => {
      await SplashScreen.hide();
    })();
  }, []);
  return (
    <>
      <ModalesContainer />
      <Route exact path="/login/forgot-password" component={ForgotPassword} />
      <Route exact path="/login/reset-password" component={ResetPassword} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/auth" component={Auth} />
      <Route exact path="/">
        <Redirect to="/app" />
      </Route>
      <Route path="/app" component={AppLoader} />
    </>
  );
};
export default Router;
