import { IndependentPlaylistsCardDefaultProps } from '../../components/IndependentPlaylistsCard';
import { SoundDesignCardDefaultProps } from '../../components/SoundDesignCard';
import SoundDesignLayout from '../../layouts/Search/SoundDesignLayout';
import AmbianceDrawer from './AmbianceDrawer';

type SoundDesignDrawerType = {
  soundDesign: SoundDesignCardDefaultProps['soundDesign'] & {
    name: string;
    independentPlaylists: Pick<IndependentPlaylistsCardDefaultProps, 'playlists' | 'total'>;
  };
};
const SoundDesignDrawer = (props: SoundDesignDrawerType) => {
  return (
    <SoundDesignLayout
      soundDesign={{ id: props.soundDesign.id, name: props.soundDesign.name }}
      ambiances={props.soundDesign.ambiances}
      ambiancesCount={props.soundDesign.ambiancesCount}
      onAmbianceClickDrawerComponent={(p) => <AmbianceDrawer ambianceId={p.ambianceId} color={p.color} />}
    />
  );
};

export default SoundDesignDrawer;
