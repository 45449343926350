import { Route, Switch, RouteComponentProps, useHistory, useLocation } from 'react-router-dom';
import ResetPasswordLayout from '../layouts/ResetPasswordLayout';
import { IonPage } from '@ionic/react';
import { usePostResetPasswordMutation } from '../services/api.service';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

const ForgotPassword = ({ match }: RouteComponentProps<Record<string, string>>) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [query, { isLoading, isSuccess, error }] = usePostResetPasswordMutation();

  const token = new URLSearchParams(useLocation().search).get('token');

  useEffect(() => {
    if (isSuccess) {
      history.push('/login');
    }
  }, [isSuccess, history]);

  if (!token) {
    history.push('/app');
    return;
  }
  return (
    <IonPage>
      <Switch>
        <Route
          exact
          path={`${match.path}`}
          component={() => (
            <ResetPasswordLayout
              onClick={(password) => {
                query({ password, token });
              }}
              success={isSuccess}
              errorMessage={error ? t('errorOccured') : undefined}
              spinning={isLoading}
            />
          )}
        />
      </Switch>
    </IonPage>
  );
};

export default ForgotPassword;
