import styled from 'styled-components';
import { IonHeader } from '@ionic/react';

export const StyledHeaderContainer = styled.div`
  padding-top: calc(var(--ion-safe-area-top) - 16px);
  margin-top: 16px;
  padding-inline-start: calc(var(--ion-safe-area-left, 0px) + 16px);
  padding-inline-end: calc(var(--ion-safe-area-right, 0px) + 16px);
  padding-bottom: 0px;
`;

export const StyledIonHeader = styled(IonHeader)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const StyledLeftHeader = styled.div`
  display: flex;
  margin-left: 4px;
`;

export const StyledRightHeader = styled.div`
  display: flex;
  align-items: center;
  margin-right: -12px;
  height: 48px;
`;

export const StyledAlbumTitleContainer = styled.div`
  & h2 {
    margin-top: 0px;
    margin-bottom: 8px;
  }
  & h5 {
    margin-top: 0px;
    margin-bottom: 8px;
  }
`;

export const StyledPageContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
