import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { PlaylistItemDefaultProps } from '../../components/PlaylistItem';
import IndependentPlaylistsLayout from '../../layouts/Search/IndependentPlaylistsLayout';
import { useLazyGetIndependentPlaylistsQuery } from '../../services/api.service';
import { RootState } from '../../store/rootReducer';
import { getSoundDesignDtoWithIndependentPlaylistsFromQuerySelector, PAGINATION_SIZE } from '../Search/selector';
import { buildPlaylistItemFromPlaylistDto } from '../Search/types';
import PlaylistDrawer from './PlaylistDrawer';

type IndependentPlaylistsDrawerType = {
  soundDesignId: string;
};

const IndependentPlaylistsDrawer = (props: IndependentPlaylistsDrawerType) => {
  const soundDesignWithIndependentPlaylistsQuery = useSelector((state: RootState) =>
    getSoundDesignDtoWithIndependentPlaylistsFromQuerySelector(state)(`${props.soundDesignId}`),
  );
  const [fetchIndependentPlaylists, { isFetching, isLoading }] = useLazyGetIndependentPlaylistsQuery();
  const initialIndependentPlaylistsState = {
    soundDesignId: '',
    playlistsOffset: 0,
    playlistsCount: 0,
    playlists: [] as PlaylistItemDefaultProps['playlist'][],
  };
  const [independentPlaylistsState, setIndependentPlaylistsState] = useState(initialIndependentPlaylistsState);

  useEffect(() => {
    fetchIndependentPlaylists(
      {
        soundDesignId: `${props.soundDesignId}`,
        sort: 'asc',
        sortBy: 'title',
        limit: PAGINATION_SIZE,
        skip: 0,
      },
      true,
    )
      .unwrap()
      .then((independentPlaylistsPlaylists) => {
        setIndependentPlaylistsState(() => ({
          soundDesignId: `${props.soundDesignId}`,
          playlists: independentPlaylistsPlaylists.playlists.map((playlistDto) =>
            buildPlaylistItemFromPlaylistDto(playlistDto),
          ),
          playlistsOffset: 0,
          playlistsCount: independentPlaylistsPlaylists.total,
        }));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.soundDesignId]);

  const independentPlaylistsDetailed =
    soundDesignWithIndependentPlaylistsQuery && independentPlaylistsState
      ? {
          soundDesign: {
            id: soundDesignWithIndependentPlaylistsQuery.id,
            name: soundDesignWithIndependentPlaylistsQuery.name,
          },
          playlists: independentPlaylistsState.playlists,
          playlistsCount: independentPlaylistsState.playlistsCount,
        }
      : undefined;

  const loadDataPlaylists = () => {
    if (!independentPlaylistsDetailed) return;
    const newOffset = independentPlaylistsState.playlistsOffset + PAGINATION_SIZE;

    fetchIndependentPlaylists(
      {
        soundDesignId: independentPlaylistsDetailed.soundDesign.id,
        sort: 'asc',
        sortBy: 'title',
        limit: PAGINATION_SIZE,
        skip: newOffset,
      },
      true,
    )
      .unwrap()
      .then((independentPlaylistsDetailedWithPlaylistsDto) => {
        setIndependentPlaylistsState((oldArtistPlaylistsState) => ({
          ...oldArtistPlaylistsState,
          playlists: [
            ...oldArtistPlaylistsState.playlists,
            ...independentPlaylistsDetailedWithPlaylistsDto.playlists.map((playlistDto) =>
              buildPlaylistItemFromPlaylistDto(playlistDto),
            ),
          ],
          playlistsOffset: newOffset,
        }));
      });
  };

  return independentPlaylistsDetailed && !isLoading ? (
    <IndependentPlaylistsLayout
      soundDesign={independentPlaylistsDetailed.soundDesign}
      playlists={independentPlaylistsDetailed.playlists}
      playlistsCount={independentPlaylistsDetailed.playlistsCount}
      onPlaylistClickDrawerComponent={(p) => <PlaylistDrawer playlistId={p.playlistId} />}
      isPlaylistsFetching={isFetching}
      loadDataPlaylists={
        independentPlaylistsDetailed.playlists.length < independentPlaylistsDetailed.playlistsCount
          ? loadDataPlaylists
          : undefined
      }
    />
  ) : (
    <IndependentPlaylistsLayout loading />
  );
};

export default IndependentPlaylistsDrawer;
