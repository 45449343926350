import React from 'react';
import { IonSkeletonText } from '@ionic/react';
import Typography from '../Typography';
import {
  StyledIonItem,
  StyledIonLabel,
  StyledTitle,
  StyledInnerCard,
  StyledPlaylistsContainer,
  StyledIndependentPlaylistsTitle,
} from './IndependentPlaylistsCard.styled';
import { useTranslation } from 'react-i18next';
import { PlaylistThumbnail } from '../PlaylistCard';

export type IndependentPlaylistsCardDefaultProps = {
  playlists: {
    id: string;
    title: string;
    duration: number;
    tracks: {
      img: string;
    }[];
  }[];
  total: number;
  onClick?: () => void;
  loading?: boolean;
};

type IndependentPlaylistsCardLoading = { loading: true };

export type IndependentPlaylistsCardProps = IndependentPlaylistsCardDefaultProps | IndependentPlaylistsCardLoading;

const IndependentPlaylistsCard = (props: IndependentPlaylistsCardProps) => {
  const { t } = useTranslation();
  return props.loading ? (
    <StyledIonItem mode="md">
      <StyledInnerCard className="loading">
        <IonSkeletonText animated={true} />
      </StyledInnerCard>
      <StyledIonLabel>
        <StyledIndependentPlaylistsTitle>
          <Typography tag="h3" className="ion-text-nowrap" loading style={{ width: 150 }} />
        </StyledIndependentPlaylistsTitle>
      </StyledIonLabel>
    </StyledIonItem>
  ) : (
    <StyledIonItem button={!!props.onClick} onClick={props.onClick} mode="md">
      <StyledInnerCard>
        <StyledPlaylistsContainer>
          {[...props.playlists, ...(Array.from({ length: 2 }).fill(null) as null[])]
            .slice(0, 2)
            .map((playlist, index) =>
              playlist ? (
                <PlaylistThumbnail
                  key={`key-playlist-${playlist.id}`}
                  tracks={playlist.tracks.slice(0, 4).map((track) => track.img)}
                  independent
                />
              ) : (
                <div key={`key-playlist-${index}`} className="playlist-thumbnail no-playlist" />
              ),
            )}
        </StyledPlaylistsContainer>
        <StyledTitle>
          <Typography tag="h3" className="ion-text-nowrap">
            {t('independentPlaylists')
              .split(' ')
              .map((word, index) => (
                <div key={`word-${index}`}>{word}</div>
              ))}
          </Typography>
        </StyledTitle>
      </StyledInnerCard>
      <StyledIonLabel>
        <StyledIndependentPlaylistsTitle>
          <Typography tag="h3" className="ion-text-nowrap">
            {`${props.total} ${t('independentPlaylists')}`}
          </Typography>
        </StyledIndependentPlaylistsTitle>
      </StyledIonLabel>
    </StyledIonItem>
  );
};

export default IndependentPlaylistsCard;
