let socket: SocketIOClient.Socket;
import store from '../store/store';
import {
  FutureTrackDto,
  ParamsDto,
  PastTrackDto,
  setOfflineMode,
  updatePlayerState,
  updateParams,
  TrackDto,
  updateVolume,
  updateVotesList,
} from '../store/playerSlice';
import { App as AppEvents } from '@capacitor/app';
import { isPlatform } from '@ionic/react';
import { api } from './api.service';
import { QobuzTrackDto } from './qobuz.service';
import { setToast } from '../store/toastSlice';
import i18n from 'i18next';

export const killSocket = () => {
  if (socket) {
    socket.removeAllListeners();
    socket.close();
  }
};

export const getSocket = () => socket;

export const initSocket = (newSocket: SocketIOClient.Socket, boxId: string) => {
  killSocket();
  socket = newSocket;
  socket.on('connect', async () => {
    await AppEvents.removeAllListeners();
    AppEvents.addListener('appStateChange', (appState) => {
      if (appState.isActive) {
        if (isPlatform('mobile') || isPlatform('mobileweb') || isPlatform('pwa')) {
          socket.emit('getWaitlist');
          socket.emit('getVoteFor');
          socket.emit('getParams');
          socket.emit('getVolumeValue');
        }
      }
    });
  });
  socket.on('connectionTimeout', () => {
    store.dispatch(setOfflineMode(true));
  });
  socket.on('reconnection', () => {
    store.dispatch(setOfflineMode(false));
    store.dispatch(setToast({ isOpen: false }));
  });
  socket.on('lastPingStatus', (failedHostnames: string[]) => {
    if (store.getState().PlayerReducer.offlineMode && !failedHostnames.length) {
      store.dispatch(setToast({ isOpen: false }));
    }
    store.dispatch(setOfflineMode(failedHostnames.length > 0));
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  socket.on('tshokoParams', (params: ParamsDto) => {
    store.dispatch(updateParams(params));
    if ((params.latestDownloadRates || []).some(({ rate }) => rate < 0.5) && store.getState().AppReducer.isAdminMode) {
      store.dispatch(
        setToast({
          isOpen: true,
          type: 'error',
          header: i18n.t('badInternetConnection'),
        }),
      );
    }
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  socket.on('votesList', (trackIds: number[]) => {
    store.dispatch(
      updateVotesList({
        votesList: trackIds,
      }),
    );
  });
  socket.on(
    'waitlist',
    (params: { currentMusic: TrackDto; waitlist: FutureTrackDto[]; lastTracks: PastTrackDto[]; timer: number }) => {
      store.dispatch(
        updatePlayerState({
          tracksLoading: false,
          waitlist: params.waitlist,
          lastTracks: params.lastTracks,
          currentMusic: params.currentMusic ? { ...params.currentMusic, timer: params.timer } : undefined,
        }),
      );
      // maj des votes
    },
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  socket.on('volumeValue', (params: { volumeValue: string }) => {
    store.dispatch(updateVolume(params.volumeValue));
    //maj du volume
  });
  socket.on('refreshBlacklist', async () => {
    store.dispatch(api.endpoints.getBlacklists.initiate(boxId, { forceRefetch: true }));
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  socket.on('endDownload', (trackId: number) => {
    // todo : move downloaded musics in another store to prevent too much rerender of waitinglist component
    // store.dispatch(addDownloadedMusic(trackId));
  });

  socket.on('getLastVolume', async (volume: string) => {
    store.dispatch(updateVolume(`${volume}`));
  });

  socket.emit('getWaitlist');
  socket.emit('getVoteFor');
  socket.emit('getParams');
  socket.emit('getVolumeValue');
};

const buildTrackDTO = (track: QobuzTrackDto) => {
  return {
    trackId: track.id,
    isrc: track.isrc,
    audioInfo: track.audio_info,
    img: track.album.image.small,
    title: `${track.work ? `${track.work} ` : ''}${track.title}${track.version ? ` (${track.version})` : ''}`,
    artist: track.performer?.name || track.album.artist.name,
    artist_id: track.performer?.id || track.album.artist.id,
    album: track.album.title,
    album_id: track.album.id,
    genre: track.album.genre.name,
    genre_id: track.album.genre.id,
    duration: track.duration * 1000,
    streamable: track.streamable,
    downloadable: track.downloadable,
    explicit: track.parental_warning,
  };
};

const proposeMusic = (track: QobuzTrackDto) => {
  socket?.emit('proposeMusic', buildTrackDTO(track));
};

const prependToPriorityQueue = (track: QobuzTrackDto) => {
  socket?.emit('prependToPriorityQueue', buildTrackDTO(track));
};

const appendToPriorityQueue = (track: QobuzTrackDto) => {
  socket.emit('appendToPriorityQueue', buildTrackDTO(track));
};

const playNewMusic = (track: QobuzTrackDto) => {
  socket?.emit('playNewMusic', buildTrackDTO(track));
};
const voteUp = (trackId: number) => {
  socket?.emit('voteUp', trackId);
};

const voteDown = (trackId: number) => {
  socket?.emit('voteDown', trackId);
};

const playThisMusic = (trackId: number) => {
  socket?.emit('playThisMusic', trackId);
};
const removeMusic = (trackId: number) => {
  socket?.emit('removeMusic', trackId);
};
const passMusic = () => {
  socket?.emit('passMusic');
};
const playNow = () => {
  socket?.emit('playNow');
};
const setPlaylistRandom = (value: boolean) => {
  socket?.emit('isPlaylistRandom', value);
};
const setJukeboxModeOn = (value: boolean) => {
  socket?.emit('setJukeboxModeOn', value);
};
const setCurrentPlaylistId = (playlistId: string) => {
  socket?.emit('setCurrentPlaylistId', playlistId);
};
const setCurrentAmbianceId = (ambianceId: string) => {
  socket?.emit('setCurrentAmbianceId', ambianceId);
};
const shutdown = () => {
  socket?.emit('shutdown');
};
const setVolume = (value: number) => {
  socket?.emit('setVolume', value);
};
const getWaitlist = () => {
  socket?.emit('getWaitlist');
};
const getParams = () => {
  socket?.emit('getParams');
};
const getVolumeValue = () => {
  socket?.emit('getVolumeValue');
};
const removeCurrentEphemeral = () => {
  socket?.emit('removeCurrentEphemeral');
};
const blacklistChanged = () => {
  socket?.emit('blacklistChanged');
};
const playSpot = (spotId: string) => {
  socket?.emit('playSpot', spotId);
};
const mute = (volume: number) => {
  socket.emit('mute', volume);
};
const unmute = () => {
  socket.emit('unmute');
};

export default {
  passMusic,
  setPlaylistRandom,
  setJukeboxModeOn,
  setCurrentPlaylistId,
  setCurrentAmbianceId,
  shutdown,
  setVolume,
  proposeMusic,
  voteUp,
  voteDown,
  playThisMusic,
  getWaitlist,
  getParams,
  getVolumeValue,
  removeMusic,
  removeCurrentEphemeral,
  blacklistChanged,
  playNow,
  playNewMusic,
  playSpot,
  mute,
  unmute,
  appendToPriorityQueue,
  prependToPriorityQueue,
};
